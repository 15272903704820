import { React} from 'react';
import {Button} from "primereact/button";


function SupportPage() {
      
    function openWhatsApp(){
        window.open('https://api.whatsapp.com/send?phone=593993703128', "_blank");
    }

    function openInstagram(){
        window.open('https://www.instagram.com/smash_latam', "_blank");
    }
    
    return (

        <div className="flex align-items-center justify-content-center">

            <div className="p-4 w-full lg:w-6">

                <div className="text-center mb-5">
                    <img src="logo512.png" alt="hyper" height={50} className="mb-3" />
                    <div className="text-100 text-3xl font-medium mb-3">Soporte Smash Latam</div>
                    <div className="text-400 text-xl font-medium mb-3">Si tienes preguntas, dudas o necesitas soporte del equipo de Smash Latam, puedes contactarnos a través de WhatsApp o Instagram, te responderemos lo más pronto posible.</div>
                </div>

                <div>                   
                    <Button severity='success' label="Cont&aacute;ctanos por WhatsApp" icon="pi pi-whatsapp" className="w-full mb-3" onClick={openWhatsApp} />
                    <Button severity='warning' label="Cont&aacute;ctanos por Instagram" icon="pi pi-instagram" className="w-full" onClick={openInstagram} />                 
                    
                </div>
            </div>
                    
        </div>

    );
}

export default SupportPage;