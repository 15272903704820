import SupportPage from './support_page';
import './styles.css';            

function App() {
  return (
    <SupportPage/>
  );
}

export default App;
